<template>
  <div class="flex-col justify-end">
    <div class="mb-5 mt-5 z-0">
      <OrderForm ref="formRef" :order="order" :small="false" />
    </div>

    <div
      class="rounded box flex items-center justify-between flex-wrap z-50 p-3"
      style="box-shadow: 0 -10px 8px -9px #dedede"
    >
      <div>
        <span class="mr-5 2xl:font-bold">
          <span>Tổng số kiện: </span>
          <span style="color: red">{{ editing?.numbers }}</span>
        </span>
        <span class="mr-5 2xl:font-bold">
          <span>Tổng trọng lượng: </span>
          <span style="color: red">{{ editing?.weight }}</span>
        </span>
        <span class="mr-5 2xl:font-bold">
          <span>Tổng COD: </span>
          <span style="color: red">{{ $h.formatCurrency(editing?.cod) }}</span>
        </span>
        <span class="mr-5 2xl:font-bold">
          <span>Vận phí: </span>
          <span style="color: red">{{ $h.formatCurrency(freight) }}</span>
        </span>
      </div>
      <div class="flex ml-auto">
        <a
          href="javascript:;"
          class="button flex items-center justify-center w-30 mr-4 inline-block bg-theme-1 text-white"
          @click="createOrder"
        >
          <CreditCardIcon class="w-4 h-4 mr-2" />
          Tạo đơn
        </a>
        <!--        <a-->
        <!--          href="javascript:;"-->
        <!--          data-toggle="modal"-->
        <!--          data-target="#order-library"-->
        <!--          class="button flex relative items-center justify-center w-30 mr-4 inline-block bg-theme-12 text-white"-->
        <!--        >-->
        <!--          <span class="absolute object-right-top -top-3 -right-3">-->
        <!--            <div-->
        <!--              class="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-theme-1 text-white"-->
        <!--              v-if="orderLibrary.length"-->
        <!--            >-->
        <!--              {{ orderLibrary.length }}-->
        <!--            </div>-->
        <!--          </span>-->
        <!--          <ServerIcon class="w-4 h-4 mr-2" />-->
        <!--          Thư viện-->
        <!--        </a>-->
        <!--        <a-->
        <!--          href="javascript:;"-->
        <!--          class="button flex items-center justify-center w-30 mr-4 inline-block bg-theme-9 text-white"-->
        <!--          @click="save"-->
        <!--        >-->
        <!--          <SaveIcon class="w-4 h-4 mr-2" />-->
        <!--          Lưu đơn-->
        <!--        </a>-->
        <a
          href="javascript:;"
          class="button flex relative items-center justify-center w-30 mr-4 inline-block bg-theme-2 text-gray-600"
          @click="
            () => {
              formRef?.resetForm();
            }
          "
        >
          <RefreshCwIcon color="grey" class="w-4 h-4 mr-2" />
          Làm mới
        </a>
      </div>
      <OrderCreateSuccessModal :ids="successIds" />
      <OrderLibrary />
    </div>
  </div>
</template>
<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";
import OrderForm from "@/components/order-form/Main.vue";
import { useCreate } from "./useCreate";

const OrderCreateSuccessModal = defineAsyncComponent(() => import("@/components/order-create-success-modal/Main.vue"));
const OrderLibrary = defineAsyncComponent(() => import("./OrderLibrary.vue"));

export default defineComponent({
  name: "OrderCreate",
  components: {
    OrderForm,
    OrderCreateSuccessModal,
    OrderLibrary,
  },
  props: [
    "receiverAddress",
    "receiverArea",
    "receiverDistrict",
    "receiverName",
    "receiverPhone",
    "receiverProvince",
    "senderAddress",
    "senderArea",
    "senderDistrict",
    "senderName",
    "senderPhone",
    "senderProvince",
    "weight",
    "quantity",
    "remark",
    "goodsName",
    "partialSign",
    "insurance",
    "cod",
  ],
  setup(props) {
    const { order, save, createOrder, editing, formRef, freight, successIds, orderLibrary } = useCreate(props);
    return {
      order,
      save,
      createOrder,
      editing,
      formRef,
      freight,
      successIds,
      orderLibrary,
    };
  },
});
</script>
