
import { defineAsyncComponent, defineComponent } from "vue";
import OrderForm from "@/components/order-form/Main.vue";
import { useCreate } from "./useCreate";

const OrderCreateSuccessModal = defineAsyncComponent(() => import("@/components/order-create-success-modal/Main.vue"));
const OrderLibrary = defineAsyncComponent(() => import("./OrderLibrary.vue"));

export default defineComponent({
  name: "OrderCreate",
  components: {
    OrderForm,
    OrderCreateSuccessModal,
    OrderLibrary,
  },
  props: [
    "receiverAddress",
    "receiverArea",
    "receiverDistrict",
    "receiverName",
    "receiverPhone",
    "receiverProvince",
    "senderAddress",
    "senderArea",
    "senderDistrict",
    "senderName",
    "senderPhone",
    "senderProvince",
    "weight",
    "quantity",
    "remark",
    "goodsName",
    "partialSign",
    "insurance",
    "cod",
  ],
  setup(props) {
    const { order, save, createOrder, editing, formRef, freight, successIds, orderLibrary } = useCreate(props);
    return {
      order,
      save,
      createOrder,
      editing,
      formRef,
      freight,
      successIds,
      orderLibrary,
    };
  },
});
