export const freights = [
  { weight: 0.25, fee: 29000 },
  { weight: 1, fee: 39000 },
  { weight: 2, fee: 49000 },
  { weight: 3, fee: 59000 },
  { weight: 4, fee: 69000 },
  { weight: 5, fee: 79000 },
  { weight: 6, fee: 89000 },
  { weight: 7, fee: 99000 },
  { weight: 8, fee: 109000 },
  { weight: 9, fee: 119000 },
  { weight: 10, fee: 129000 },
  { weight: 11, fee: 139000 },
  { weight: 12, fee: 149000 },
  { weight: 13, fee: 159000 },
  { weight: 14, fee: 169000 },
  { weight: 15, fee: 179000 },
  { weight: 16, fee: 189000 },
  { weight: 17, fee: 199000 },
  { weight: 18, fee: 209000 },
  { weight: 19, fee: 219000 },
  { weight: 20, fee: 229000 },
  { weight: 21, fee: 239000 },
  { weight: 22, fee: 249000 },
  { weight: 23, fee: 259000 },
  { weight: 24, fee: 269000 },
  { weight: 25, fee: 279000 },
  { weight: 26, fee: 289000 },
  { weight: 27, fee: 299000 },
  { weight: 28, fee: 309000 },
  { weight: 29, fee: 319000 },
  { weight: 30, fee: 329000 },
  { weight: 31, fee: 339000 },
  { weight: 32, fee: 349000 },
  { weight: 33, fee: 359000 },
  { weight: 34, fee: 369000 },
  { weight: 35, fee: 379000 },
  { weight: 36, fee: 389000 },
  { weight: 37, fee: 399000 },
  { weight: 38, fee: 409000 },
  { weight: 39, fee: 419000 },
  { weight: 40, fee: 429000 },
  { weight: 41, fee: 439000 },
  { weight: 42, fee: 449000 },
  { weight: 43, fee: 459000 },
  { weight: 44, fee: 469000 },
  { weight: 45, fee: 479000 },
  { weight: 46, fee: 489000 },
  { weight: 47, fee: 499000 },
  { weight: 48, fee: 509000 },
  { weight: 49, fee: 519000 },
  { weight: 50, fee: 529000 },
  { weight: 51, fee: 539000 },
  { weight: 52, fee: 549000 },
  { weight: 53, fee: 559000 },
  { weight: 54, fee: 569000 },
  { weight: 55, fee: 579000 },
  { weight: 56, fee: 589000 },
  { weight: 57, fee: 599000 },
  { weight: 58, fee: 609000 },
  { weight: 59, fee: 619000 },
  { weight: 60, fee: 629000 },
  { weight: 61, fee: 639000 },
  { weight: 62, fee: 649000 },
  { weight: 63, fee: 659000 },
  { weight: 64, fee: 669000 },
  { weight: 65, fee: 679000 },
  { weight: 66, fee: 689000 },
  { weight: 67, fee: 699000 },
  { weight: 68, fee: 709000 },
  { weight: 69, fee: 719000 },
  { weight: 70, fee: 729000 },
  { weight: 71, fee: 739000 },
  { weight: 72, fee: 749000 },
  { weight: 73, fee: 759000 },
  { weight: 74, fee: 769000 },
  { weight: 75, fee: 779000 },
  { weight: 76, fee: 789000 },
  { weight: 77, fee: 799000 },
  { weight: 78, fee: 809000 },
  { weight: 79, fee: 819000 },
  { weight: 80, fee: 829000 },
  { weight: 81, fee: 839000 },
  { weight: 82, fee: 849000 },
  { weight: 83, fee: 859000 },
  { weight: 84, fee: 869000 },
  { weight: 85, fee: 879000 },
  { weight: 86, fee: 889000 },
  { weight: 87, fee: 899000 },
  { weight: 88, fee: 909000 },
  { weight: 89, fee: 919000 },
  { weight: 90, fee: 929000 },
  { weight: 91, fee: 939000 },
  { weight: 92, fee: 949000 },
  { weight: 93, fee: 959000 },
  { weight: 94, fee: 969000 },
  { weight: 95, fee: 979000 },
  { weight: 96, fee: 989000 },
  { weight: 97, fee: 999000 },
  { weight: 98, fee: 1009000 },
  { weight: 99, fee: 1019000 },
  { weight: 100, fee: 1029000 },
];
