import { computed, ref } from "vue";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import { Order } from "@/configs/orders";
import { freights } from "@/configs/freights";
import { authService } from "@/services";
import { orderService } from "@/services/order";
import { setOrderLibrary, getOrderLibrary } from "@/libs/localStorage";
import { getWaring, showError } from "@/libs/notification";
import { useStore } from "@/store";

export const useCreate: any = (props: any) => {
  const formRef = ref(null as any);
  const order = ref<any>({
    receiverAddress: props.receiverAddress,
    receiverArea: props.receiverArea,
    receiverCity: props.receiverDistrict,
    receiverName: props.receiverName,
    receiverPhone: props.receiverPhone,
    receiverProvince: props.receiverProvince,
    senderAddress: props.senderAddress,
    senderArea: props.senderArea,
    senderCity: props.senderDistrict,
    senderName: props.senderName,
    senderPhone: props.senderPhone,
    senderProvince: props.senderProvince,
    weight: props.weight,
    numbers: props.quantity,
    remark: props.remark,
    goodsName: props.goodsName,
    insurance: props.insurance,
    cod: props.cod,
    goodsType: "Hàng hóa",
    orderManner: "EXPRESS",
    payMethod: "PP_PM",
    partialSign: props.partialSign || true,
  });
  const successIds = ref<string[]>([]);
  const store = useStore();
  const orderLibrary = computed(() => store.state.main.orderLibrary);

  const save = () => {
    if (formRef.value?.validateForm()) {
      const orderInput = formRef.value?.formData;
      if (orderInput.id == null) {
        orderInput.id = uuid();
      }
      const temp = orderLibrary.value;
      const idx = temp.findIndex((el) => el.id === orderInput.id);
      if (idx !== -1) {
        temp[idx] = orderInput;
      } else {
        temp.push(orderInput);
      }
      store.commit("main/SET_ORDER_LIBRARY", temp);

      formRef.value?.resetForm();
    }
  };

  const createOrder = async () => {
    if (formRef.value?.validateForm()) {
      const item = formRef.value?.formData;

      const orders = [
        {
          customCode: item.customCode || uuid(),
          sendDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          // sender
          senderAddress: item.senderAddress,
          senderArea: item.senderArea,
          senderDistrict: item.senderCity,
          senderName: item.senderName,
          senderProvince: item.senderProvince,
          senderPhone: item.senderPhone,
          // receiver
          receiverPhone: item.receiverPhone,
          receiverAddress: item.receiverAddress,
          receiverArea: item.receiverArea,
          receiverDistrict: item.receiverCity,
          receiverName: item.receiverName,
          receiverProvince: item.receiverProvince,
          // Goods
          goodsName: item.goodsName,
          // goodsType: item.goodsType  ,
          weight: item.weight?.toString(),
          quantity: item.numbers,
          // goodsValue: item.goodsValue?.toString(),
          // Delivery
          cod: item.cod?.toString() || "0",
          insurance: item.insurance ? "1" : "0",
          partialSign: item.partialSign ? "1" : "0",
          remark: item.remark || "",
          payMethod: item.payMethod,
          excelIndex: item.excelIndex,
          goodsValue: "0",
        },
      ];
      const toast = getWaring("Đang xử lý");
      toast.showToast();
      try {
        const data = await orderService.createOrders({ orders });
        if (data?.result?.successIds?.length) {
          successIds.value = data?.result?.successIds;
          cash("#order-create-success-modal").modal("show");
        } else {
          showError("Có lỗi xảy ra");
        }
      } catch (error) {
        showError(error);
      } finally {
        toast.hideToast();
      }
    }
  };

  const calculateFreight = (weight: number) => {
    const receiverProvince = formRef.value?.formData?.receiverProvince;
    const userInfo = authService.getUserInfoLocal();
    // Tính phí nội thành

    if (receiverProvince === "Hà Nội" && userInfo?.hnFee) {
      const json = JSON.parse(userInfo.hnFee).map((item: any) => {
        return {
          fee: parseFloat(item.fee),
          weight: parseFloat(item.weight),
        };
      });
      const feeConfigs = _.sortBy(json, (o) => o.weight);
      const find = feeConfigs.find((config) => weight <= config.weight);
      if (find) {
        return find.fee;
      }
    } else if (userInfo?.fee) {
      const json = JSON.parse(userInfo.fee).map((item: any) => {
        return {
          fee: parseFloat(item.fee),
          weight: parseFloat(item.weight),
        };
      });
      const feeConfigs = _.sortBy(json, (o) => o.weight);
      const find = feeConfigs.find((config) => weight <= config.weight);
      if (find) {
        return find.fee;
      }
    }
    const find = freights.find((config) => weight <= config.weight);
    return find?.fee;
  };

  const editing = computed(() => formRef.value?.formData);

  const freight = computed(() => {
    const weight = formRef.value?.formData?.weight;
    return calculateFreight(weight);
  });

  return {
    order,
    save,
    createOrder,
    editing,
    formRef,
    freight,
    successIds,
    orderLibrary,
  };
};
